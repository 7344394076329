import React, { useEffect } from "react";
import { 
  Chip, 
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper
} from "@mui/material";
import { textPanelSelectionUpdater } from '../utils/uiLogic';
/**
 * Build a lookup dictionary: 
 *   key = `${var}||${surface}`
 *   value = { sentIndex, start, end }
 * 
 * This allows us to quickly find the position (sentIndex, start offset)
 * of any node from {var, surface}.
 */
function buildTokenPositionLookup(tokens) {
  // tokens is an array-of-arrays: tokens[sentIndex] = listOfTokens
  // each token has: { var, surface, start, end, ... }
  const lookup = {};
  tokens.forEach((sentTokens, sIndex) => {
    sentTokens.forEach((tk) => {
      const key = `${tk.var}`;
      lookup[key] = {
        sentIndex: sIndex,
        start: tk.start,
        end: tk.end
      };
    });
  });
  return lookup;
}

/**
 * Return true if (sIndexA, startA) is strictly after (sIndexB, startB) 
 * in document order.
 */
function isAfter(sIndexA, startA, sIndexB, startB) {
  if (sIndexA > sIndexB) return true;
  if (sIndexA < sIndexB) return false;
  // same sentence => compare start offset
  return startA > startB;
}

/**
 * Compare two tokens by (sentIndex, start). 
 * Return negative if A < B, positive if A > B, zero if same.
 */
function compareTokensByPosition(a, b) {
  // a, b are objects like {sentIndex, start}
  if (a.sentIndex !== b.sentIndex) {
    return a.sentIndex - b.sentIndex;
  }
  return a.start - b.start;
}

function DocViewTextPanel2({
  text,
  tokens,                // array-of-arrays of token objects
  setTokens,
  selectedCluster,       
  allClusters,
  setAllClusters,
  uploadAMRCorefs,
  scrollToAMR,
  suggestedAllClusters
}) {

  // =========================================
  // 1) Handle user clicks (toggle selection)
  // =========================================
  const handleTokenClick = (e, sentId, tokenVar, tokenSurface) => {
    if (selectedCluster >= 0) {
      // Toggle background color in local tokens
      console.log('tokens before: ', tokens);
      const newTokens = tokens.map((perSentTokens, sId) => 
        sId === sentId 
          ? perSentTokens.map((tk) =>
              tk.var === tokenVar
                ? { 
                    ...tk, 
                    bgc: tk.bgc === "#007bff" ? "white" : "#007bff"
                  }
                : tk
            )
          : perSentTokens
      );
      console.log('newTokens: ', newTokens);
      setTokens(newTokens);

      // // Update cluster in allClusters
      // const userNodes = [...allClusters[selectedCluster].nodes];
      // const idx = userNodes.findIndex(
      //   (nd) => nd.var === tokenVar
      // );
      // if (idx !== -1) {
      //   userNodes.splice(idx, 1);
      // } else {
      //   userNodes.push({ var: tokenVar, surface: tokenSurface });
      // }

      // // Optionally update cluster name if empty
      // const clusterName = allClusters[selectedCluster].name;
      // const updatedName = clusterName.length === 0 
      //   ? tokenSurface 
      //   : clusterName;

      // const newClusters = {
      //   ...allClusters,
      //   [selectedCluster]: {
      //     ...allClusters[selectedCluster],
      //     nodes: userNodes,
      //     name: updatedName
      //   }
      // };
      // setAllClusters(newClusters);
      // uploadAMRCorefs(newClusters);
      const newNodes = [...allClusters[selectedCluster]['nodes']];
      textPanelSelectionUpdater(newNodes, allClusters, setAllClusters, selectedCluster, tokenVar, tokenSurface, uploadAMRCorefs);
    }
  };

  const turnoffAllSuggestionTokens = (allTokens) => {
    const newTokens = allTokens.map((perSentTokens) =>
      perSentTokens.map((tk) => {
        if (tk.bgc === "#40a070"){
          return { ...tk, bgc: "white"}
        }
        return tk;
      })
    );
    return newTokens;
  };
  // ==================================================
  // 2) Whenever user cluster changes or suggestions
  //    arrive, color the “next” suggestive token #40a070
  // ==================================================
  useEffect(() => {
    if (selectedCluster < 0) return;

    const userCluster = allClusters[selectedCluster];
    if (!userCluster || !userCluster.nodes || userCluster.nodes.length === 0) {
      // No user-selected nodes => no “furthest” => no next token to suggest
      return;
    }

    // Build lookup from {var}||{surface} => {sentIndex, start, end}
    const positionLookup = buildTokenPositionLookup(tokens);

    // Find the furthest user token by (sentIndex, start)
    let furthestPos = { sentIndex: -1, start: -1 };
    userCluster.nodes.forEach((userNode) => {
      const key = `${userNode.var}`;
      const pos = positionLookup[key];
      if (!pos) return; // safety
      if (isAfter(pos.sentIndex, pos.start, furthestPos.sentIndex, furthestPos.start)) {
        furthestPos = { sentIndex: pos.sentIndex, start: pos.start };
      }
    });

    // Find best overlap from suggestedAllClusters
    let bestClusterKey = null;
    let maxOverlap = 0;
    if (suggestedAllClusters && typeof suggestedAllClusters === "object") {
      for (const [key, cluster] of Object.entries(suggestedAllClusters)) {
        let overlapCount = 0;
        cluster.nodes.forEach((sgNode) => {
          const inUser = userCluster.nodes.find(
            (n) => n.var === sgNode.var && n.surface === sgNode.surface
          );
          if (inUser) overlapCount++;
        });
        if (overlapCount > maxOverlap) {
          maxOverlap = overlapCount;
          bestClusterKey = key;
        }
      }
    }
    if (!bestClusterKey || maxOverlap === 0) {
      // No suitable suggestion => do nothing
      console.log('no suitable suggestion');
      return;
    }
    
    const bestSuggestedCluster = suggestedAllClusters[bestClusterKey];
    console.log('bestClusterKey: ', bestClusterKey);
    console.log('bestSuggestedCluster: ', suggestedAllClusters[bestClusterKey]);

    // Among bestSuggestedCluster's nodes, find all that are strictly after 'furthestPos'
    // Then pick the earliest among them.
    const candidatePositions = [];
    bestSuggestedCluster.nodes.forEach((sgNode) => {
      const key = `${sgNode.var}`;
      const pos = positionLookup[key];
      if (!pos) return; // safety
      // strictly after => (pos > furthestPos) in doc order
      if (
        pos.sentIndex > furthestPos.sentIndex ||
        (pos.sentIndex === furthestPos.sentIndex && pos.start > furthestPos.start)
      ) {
        candidatePositions.push({ ...pos, var: sgNode.var, surface: sgNode.surface });
      }
    });
    
    if (candidatePositions.length === 0) {
      // No next token is strictly after => nothing to highlight
      console.log('candidatePositions.length === 0');
      const newTokens = turnoffAllSuggestionTokens(tokens);
      setTokens((prev) => {
        const changed = JSON.stringify(prev) !== JSON.stringify(newTokens);
        return changed ? newTokens : prev;
      });
      return;
    }
    // Sort by doc order and pick the earliest
    candidatePositions.sort((a, b) => compareTokensByPosition(a, b));
    const nextToken = candidatePositions[0]; // the earliest after the furthest user token
    console.log('nextToken: ', nextToken);
    // Recolor tokens: 
    //  - user cluster => #007bff
    //  - next suggested => #40a070
    //  - everything else => white
    const userNodeSet = new Set(
      userCluster.nodes.map(n => `${n.var}`)
    );
    const nextTokenKey = `${nextToken.var}`;

    const newTokens = tokens.map((perSentTokens) =>
      perSentTokens.map((tk) => {
        
        if (userNodeSet.has(tk.var)) {
          // user-selected => #007bff
          return { ...tk, bgc: "#007bff" };
        } 
        if (tk.var === nextTokenKey) {
          // next suggestive => green
          return { ...tk, bgc: "#40a070" };
        }
        // default => white
        return { ...tk, bgc: "white" };
      })
    );

    // Only update if changed
    setTokens((prev) => {
      const changed = JSON.stringify(prev) !== JSON.stringify(newTokens);
      return changed ? newTokens : prev;
    });
  }, [selectedCluster, allClusters, suggestedAllClusters, setTokens, tokens]);

  // ===============================================
  // 3) Render text row-by-row with clickable Chips
  // ===============================================
  const renderTextWithChips = (sentID, sentenceText, perSentTokens) => {
    // Sort tokens by start offset in case they are not sorted
    const sortedTokens = [...perSentTokens].sort((a, b) => a.start - b.start);
    const elements = [];
    let lastIndex = 0;

    sortedTokens.forEach((token, i) => {
      if (token.start > lastIndex) {
        elements.push(
          <span
            key={`plain-${sentID}-${i}`}
            style={{ whiteSpace: "pre-wrap" }}
          >
            {sentenceText.substring(lastIndex, token.start)}
          </span>
        );
      }
      elements.push(
        <Chip
          key={`chip-${sentID}-${token.var}-${token.start}-${token.end}`}
          label={sentenceText.substring(token.start, token.end)}
          variant="outlined"
          size="small"
          sx={{
            margin: 0,
            display: "inline-flex",
            verticalAlign: "middle",
            fontSize: "1rem",
            border: "1px solid #000000",
            "& .MuiChip-label": {
              paddingLeft: "1px",
              paddingRight: "1px"
            },
            backgroundColor: token.bgc,
            color: token.bgc === "white" ? "black" : "white",
            "&&:hover": {
              backgroundColor: "orange"
            }
          }}
          onClick={(e) =>
            handleTokenClick(
              e,
              sentID,
              token.var,
              sentenceText.substring(token.start, token.end)
            )
          }
        />
      );
      lastIndex = token.end;
    });

    // If there's any trailing text after the last token
    if (lastIndex < sentenceText.length) {
      elements.push(
        <span
          key={`tail-${sentID}-${lastIndex}`}
          style={{ whiteSpace: "pre-wrap" }}
        >
          {sentenceText.substring(lastIndex)}
        </span>
      );
    }
    return elements;
  };

  // ===========================
  // 4) Main table-based render
  // ===========================
  return (
    <TableContainer component={Paper}>
      <Table size="small" aria-label="a dense table">
        <TableBody>
          {text.map((sent, index) => (
            <TableRow
              key={index}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell
                component="th"
                scope="row"
                sx={{ fontSize: "1rem" }}
                onClick={() => scrollToAMR(index)}
              >
                {index + 1}
              </TableCell>
              <TableCell align="left" sx={{ fontSize: "1rem" }}>
                {renderTextWithChips(index, sent, tokens[index] || [])}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default DocViewTextPanel2;