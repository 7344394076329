import React, { useState, useRef, useEffect } from 'react';
import { ExtraEditor, AMREditor } from './Editor';

// import useLocalStorage from '../hooks/useLocalStorage';

import WorksetManager from "./Uploader";
import ProfileManager from "./ProfileManager";
import BridgingMapper from './CorefCluster/AMRMapper/BridgingMapper';

import SplitPane from "react-split-pane";

// import Col from 'react-bootstrap/Col';

import DocViewTextPanel from './DocViewTextPanel';
import DocAMRPanel from './DocAMRPanel';
import './App.css';
import Pagination from '@mui/material/Pagination';
// import usePagination from "./Pagination";
import { default as annotationData } from "./MOCK_DATA.json";


import PropbankRoleListPop from './PropbankRoleListPop';
import ReleaseListNWPop from './ReleaseListNWPop';
import Fade from '@mui/material/Fade';
// var propbank = data.PropBank;
// var rolesets = [];
// for (let i = 0; i < propbank.length; i++) {
//   for (var j = 0; j < propbank[i].predicates.length; j++) {
//     rolesets.push(...propbank[i].predicates[j].rolesets);
//   }
// }

// for New menu compoenents
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import { Search, SearchIconWrapper, StyledInputBase } from './menustyle';
// end of New menu components
import { LOGOUT } from '../store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { postAMRData, getReleaseSearch, getPropbankSearch, getParserQuery, getDocBridgeDataFromWorkSet, getProfileData, postAMRBridgeCorefs, getDocAMRFromWorkSet, putCorefCopilotPref } from '../services/auth';
// const BASE_API_URL = 'http://127.0.0.1:5000';
import { preprocessCode, tokenizePenman, sentenceLabelVars } from './penmanLanguage';
import ApiQueryForm from './InterpreterForm';


const role_set_init = [{ "roleset_id": "examples", "examples": [{ "example_name": "1st example", "example_text": "first example text", "args": [{ "arg_text": "example", "f": "", "n": "0" }], "rel": { "rel_text": "st" } }] }];
const release_amr_init = [{ "snt": "this is a test sent", "amr": "(t / this\n  :equal (s / snt\n    :mod (t / test)\n  )\n)" }];

const mock_doc_text = ["As a test to determine if grownups are as enlightened as a child, the narrator shows them a picture depicting a boa constrictor that has eaten an elephant. ", 
  "The adults always reply that the picture depicts a hat, and so he knows to only talk of \"reasonable\" things to them, rather than the fanciful.", 
  "The narrator becomes an aircraft pilot, and one day, his plane crashes in the Sahara desert, far from civilization.", 
  " The narrator must fix his aeroplane before his supply of water runs out. ", 
  " Here, he is greeted by a young boy nicknamed \"the little prince.\" \n", 
  "The prince asks the narrator to draw a sheep. ", 
  "The narrator first shows him the picture of the elephant inside the snake, which, to the narrator's surprise, the prince interprets correctly. ", 
  "After three failed attempts at drawing a sheep, the frustrated narrator draws a crate, claiming the sheep is inside. This turns out to be the exact drawing the prince wanted."];

const mock_doc_tokens = [[{start: 26, end: 34, var: 's1g', bgc: 'white', surface: 'grownups'}, {start: 66, end: 78, var: 's1n', bgc: 'white', surface: 'narrator'}, {start: 85, end: 89, var: 's1t', bgc: 'white', surface: 'them'}, {start: 90, end: 99, var: 's1p', bgc: 'white', surface: 'a picture'}], 
  [{start: 0, end: 10, var: 's2a', bgc: 'white', surface: 'The adults'}, {start: 29, end: 40, var: 's2p', bgc: 'white', surface: 'the picture'}, {start: 63, end: 65, var: 's2h', bgc: 'white', surface: 'he'}, {start: 111, end: 115, var: 's2a', bgc: 'white', surface: 'them'}], 
  [], [], [], [], [], []];

const mock_amrs = ['(s1s / show-01\n    :ARG0 (s1z1 / person\n        :ARG0-of (z2 / narrate-01))\n    :ARG1 (s1p / picture\n        :ARG0-of (s1d / depict-01\n            :ARG1 (s1c / constrictor\n                :mod (s1b / boa)\n                :ARG0-of (s1e / eat-01\n                    :ARG1 (s1e / elephant)))))\n    :ARG2 (s1t / they)\n    :purpose (s1t1 / test-01\n        :ARG0 s1z1\n        :ARG2 (s1d1 / determine-01\n            :ARG0 s1z1\n            :ARG1 (s1e1 / enlighten-01\n                :ARG1 (s1g / grown-up)\n                :ARG2-of (s1h / have-degree-91\n                    :ARG1 s1g\n                    :ARG3 (s1e2 / equal)\n                    :ARG4 (s1c1 / child\n                        :domain s1g))))))\n',
  '(s2r / reply-01\n    :ARG0 (s2a / adult)\n    :ARG1 (s2d / depict-01\n        :ARG0 (s2p / picture)\n        :ARG1 (s2h1 / hat))\n    :time (s2a1 / always)\n    :ARG0-of (s2c / cause-01\n        :ARG1 (s2k / know-01\n            :ARG0 (s2h / he)\n            :ARG1 (s2t / talk-01\n                :ARG0 s2h\n                :ARG1 (s2t1 / thing\n                    :ARG1-of (s2r / reasonable-02)\n                    :ARG1-of (s2i / instead-of-91\n                        :ARG2 (s2f / fancy)))\n                :ARG2 s2a\n                :mod (s2o / only)))))',
  '', '', '', '', '', ''
  ];
 
const mock_cluters = {
  '1': {
    'id': '1',
    'name': 'grownups',
    'nodes': [{var: 's1g', surface: 'grownups'}, {var: 's1t', surface: 'them'}, {var: 's2a', surface: 'adult'}, {var: 's2a', surface: 'them'}]
  },
  '2': {
    'id': '2',
    'name': 'narrator',
    'nodes': [{var: 's1n', surface: 'narrator'}, {var: 's2h', surface: 'he'}]
  },
  '3': {
    'id': '3',
    'name': 'picture',
    'nodes': [{var: 's1p', surface: 'picture'}, {var: 's2p', surface: 'picture'}]
  }
};

const amrVarPattern = /s(\d+)\w\d*/;

/**
 * Reformat the mentions in aaaVarClusters to an object keyed by index.
 * 
 * @param {Object} amrVarClusters - The input object containing a `mentions` array.
 * @returns {Object} An object where each key is the stringified index, 
 *                   and each value is { id, name, nodes } taken from the mention.
 */
function reformatMentions(amrVarClusters) {
  const reformatted = {};
  if (amrVarClusters === null){
    return reformatted;
  } else {
    amrVarClusters.mentions.forEach((clusterDict, index) => {
      const id_str = index.toString();

      reformatted[id_str] = {
        id: id_str,
        name: clusterDict.name,
        nodes: clusterDict.nodes,
        coreNode: clusterDict.coreNode,
        type: clusterDict.type
      };
    });

    return reformatted;
  }
}


function BridgingDocView() {
  // const [text, setText] = useLocalStorage('text', '');
  // const [css, setCss] = useLocalStorage('css', '');
  // const [js, setJs] = useLocalStorage('js', '');

  const dispatch = useDispatch();
  // const [checked, setChecked] = useState(true);
  const navigate = useNavigate();
  const account = useSelector((state) => state.account);

  const [heightValue, setHeightValue] = useState("485px");
  const [keyword, setKeyword] = useState('');
  const [rskeyword, setRSKeyword] = useState('');
  // const [searchword, setSearchWord] = useState('');

  const [uploadShow, setUploadShow] = useState(false);
  const [profileShow, setProfileShow] = useState(false);

  const [anchorElDrawer, setAnchorElDrawer] = useState(null);

  // amr panel states
  // const preprocessedAMRs = mock_amrs.map(amrStr => preprocessCode(amrStr));
  // const amrTokens = preprocessedAMRs.map(amrStr => tokenizePenman(amrStr));
  // const [amrData, setAMRData] = useState(amrTokens);
  const [amrData, setAMRData] = useState([]);
  const [currentVars, setCurrentVars] = useState([]);

  // shared information and function between text and cluster
  // ----------------------------------------------------
  const [currentCluster, setCurrentCluster] = useState(-1);

  // const [allClusters, setAllClusters] = useState(mock_cluters);
  const [allClusters, setAllClusters] = useState({});

  // const [styledTokens, setStyledTokens] = useState(mock_doc_tokens);
  const [styledTokens, setStyledTokens] = useState([]);

  // const [docText, setDocText] = useState(mock_doc_text);
  const [docText, setDocText] = useState([]);
  // load data from backend ---------------
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [lastWorksetID, setLastWorksetID] = useState(null);
  // const [useCorefCopilot, setUseCorefCopilot] = useState(false);
  // AI interpreter config ----------------
  const [useAIInterpreter, setUseAIInterpreter] = useState(false);
  const [showInterpreter, setShowInterpreter] = useState(false);
  const [endpoint, setEndpoint] = useState('');
  const [apiKey, setApiKey] = useState('');
  const [queryText, setQueryText] = useState('');

  const handleInterpreterSwitchChange = async (e) => {
    e.preventDefault();
    setUseAIInterpreter(e.target.checked);
    if (e.target.checked){
      setShowInterpreter(true);
    }
  };

  // auto focus of the AMR by clicking on sentence index ----
    // Create an array of refs for each Box
  const boxRefs = useRef([]);

  // Scroll the target box into view
  const scrollToAMRBox = (index) => {
    if (boxRefs.current[index]) {
      boxRefs.current[index].scrollIntoView({
        behavior: 'smooth',
        block: 'center', // Scrolls the target to the center of the view
      });
    }
  };

  // END auto focus -----------------------------------------

  useEffect(() => {
    const fetchClusterData = async () => {
      setLoading(true);
      try {
        const userProfile = await getProfileData();
        const temp_lastWorksetID = userProfile['last_workset_id'];
        console.log('userProfile["coref_copilot_on"]: ', userProfile['coref_copilot_on']);
        console.log('userProfile: ', userProfile);
        
        const amrBcorefs = await getDocBridgeDataFromWorkSet(temp_lastWorksetID);
        // console.log('amrcorefs_raw_data:', amrcorefs_raw_data);
        console.log('amrBcorefs: ', amrBcorefs);
        const reformatedAMRVarClusters = reformatMentions(amrBcorefs);
        // const suggestAMRVarClusters = reformatMentions(amrcorefs_raw_data['ephemeral_amrcorefs']);
        // console.log('suggestAMRVarClusters: ', suggestAMRVarClusters);

        // prepare text:
        const response = await getDocAMRFromWorkSet(temp_lastWorksetID);
        const amrData = response.amrs;
        const sents = amrData.map(amr => amr.text);
        console.log('sents: ', sents);
        console.log('amrData: ', amrData);
        // prepare styled tokens in the text

        const alignedTks = amrData.map((amr, sid) => amr.AMR_aligns.map(alignDict => ({...alignDict, 'var': `s${(sid+1)}${alignDict.var?alignDict.var:alignDict.label}`, bgc: 'white'})));
        console.log('alignedTks: ', alignedTks);
        // prepare amrs:
        const preprocessedAMRs = amrData.map(amr => preprocessCode(amr.implicit_amr_data.implicit_filled_AMR));
        const amrTokens = preprocessedAMRs.map(amrStr => tokenizePenman(amrStr));
        const relabeledAMRTokens = sentenceLabelVars(amrTokens);
        console.log('amrTokens: ', amrTokens);
        console.log('relabeledAMRTokens: ', relabeledAMRTokens);
        //set all together:
        setDocText(sents);
        setStyledTokens(alignedTks);
        setAMRData(relabeledAMRTokens);
        
        setAllClusters(reformatedAMRVarClusters);

        setLastWorksetID(temp_lastWorksetID);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };
    console.log('in useEffect BridgingDocView');
    fetchClusterData();
  }, []);

  // --------START upload AMR coref to the backend-----
  const handleUploadAMRCorefs = async (newClusters) => {
    // let mentions_ls = [];
    // Object.keys(allClusters).forEach(key => {
    //   // Concatenate the nodes array from each cluster into the mentions array
    //   mentions_ls = mentions_ls.concat(allClusters[key].nodes);
    // });
    // let mentions_ls = Object.keys(newClusters).map(key => newClusters[key].nodes);
    // console.log('mentions_ls: ', mentions_ls);
    const mentions_ls = Object.keys(newClusters)
      .map(key => [parseInt(key), newClusters[key]]) // Convert to list of key-value pairs
      .sort((a, b) => a[0] - b[0])            // Sort by key (integer)
      .map(([_, value]) => value);    
    console.log('mentions_ls: ', mentions_ls);  
    const amrcorefs_data = {workset: lastWorksetID, mentions: mentions_ls};
    try {
      const resp = await postAMRBridgeCorefs(amrcorefs_data);
    } catch (error) {
      console.error('Error post amrcorefs:', error);
    }
  };
  // --------END upload AMR coref to the backend-------

  // --------------
  const handleCreateNewCluster = (bridgeType) => {
    console.log('handleCreateNewCluster!');
    let newId = Object.keys(allClusters).length;
    while (allClusters.hasOwnProperty(newId)) {
      newId++;
    }
    console.log('newId: ', newId);

    // Add the new element to the dictionary
    setAllClusters((prevClusters) => ({
      ...prevClusters,
      [newId]: {'id': newId, 'nodes': [], 'name': '<new cluster>', 'type': bridgeType, 'coreNode': null},
    }));
    // handleUploadAMRCorefs();

  };
  
  const updateTokenBgc = (tokenDict, amrVar) => {
    if (tokenDict.var === amrVar) {
      console.log(`Updating amrVar ${amrVar} bgc: ${tokenDict.bgc}`);
      const newbgc = tokenDict.bgc === 'white' ? '#539eeb' : 'white';
      return { ...tokenDict, bgc: newbgc };
    } else {
      return { ...tokenDict };
    }
  };
  
  const handleAMRTokenBGCChangeWithCluster = () => {
    const newAMRData = amrData.map((perSntAMRTokens) => 
      perSntAMRTokens.map((amrToken) => {
        const index = allClusters[currentCluster.toString()]['nodes'].findIndex((nodeDict) => nodeDict.var === amrToken.value);
        if (index !== -1) {
          return {...amrToken, bgc: 'black'};
        } else {
          return {...amrToken, bgc: '#F3F3F3'};
        }
      }));

    setAMRData(newAMRData);
  };

  const handleRemoveCluster = (id) => {
    const newClusters = Object.entries(allClusters);
    const filteredClusters = newClusters.filter(([key]) => key !== id );
    const updatedClusters = Object.fromEntries(filteredClusters);
    setAllClusters(updatedClusters);
    setCurrentCluster(-1);
    handleUploadAMRCorefs(updatedClusters);
  };
  
  useEffect(() => {
    if (currentCluster !== -1){
      console.log('currentCluster triggered useEffect', currentCluster);
      if (currentCluster === null){
        
        const newStyledTokens = styledTokens.map(perSentTokens => 
          perSentTokens.map(tokenDict => ({
            ...tokenDict,
            bgc: 'white'
          }))
        );
        setStyledTokens(newStyledTokens);
        const newAMRData = amrData.map((perSntAMRTokens) => 
          perSntAMRTokens.map((amrToken) => ({...amrToken, bgc: '#F3F3F3'})));

        setAMRData(newAMRData);
      } else {
        
        const newStyledTokens = styledTokens.map(perSentTokens => 
          perSentTokens.map(tokenDict => ({
            ...tokenDict,
            bgc: allClusters[currentCluster]['nodes'].some(node => node.var === tokenDict.var) ? '#007bff' : 'white'
          }))
        );
        setStyledTokens(newStyledTokens);
        handleAMRTokenBGCChangeWithCluster();
      }
    }
  }, [currentCluster]);

  useEffect(() => {
    if (currentCluster !== -1 && Object.keys(allClusters).length > 0){
        if (currentCluster !== null){
          handleAMRTokenBGCChangeWithCluster();
        } 
    }
  }, [allClusters]);
  // ----------------------------------------------------

  const handleDrawerClick = (event) => {
    setAnchorElDrawer(event.currentTarget);
  };
  const handleDrawerClose = () => {
    setAnchorElDrawer(null);
  };


  const handleChangeCurrentWorkSet = (data, lastIndex) => {
    // do something to update the text
    console.log('have not implemented');
  };

  const handleLogout = () => {
    setAnchorElDrawer(null);
    dispatch({ type: LOGOUT });
    navigate('/login', { replace: true });
  };

  const handleHomeClick = () => {
    handleDrawerClose(); // Assuming you want to close the drawer when Home is clicked
    navigate('/');
  };
  const handleBridgingDocView = () => {
    
    handleDrawerClose(); // Assuming you want to close the drawer when Home is clicked
  };

  const handleDocView2 = () => {
    navigate('/docview2');
    handleDrawerClose(); // Assuming you want to close the drawer when Home is clicked
  };
  const handleDocView = () => {
    navigate('/docview');
    handleDrawerClose();
  };


  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static" color="inherit" sx={{ height: 60 }}>
          <Toolbar>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="open drawer"
              aria-controls={Boolean(anchorElDrawer) ? 'fade-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={Boolean(anchorElDrawer) ? 'true' : undefined}
              onClick={handleDrawerClick}
              sx={{ mr: 2 }}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="fade-menu"
              MenuListProps={{
                'aria-labelledby': 'fade-button',
              }}
              anchorEl={anchorElDrawer}
              open={Boolean(anchorElDrawer)}
              onClose={handleDrawerClose}
              TransitionComponent={Fade}
            >
                            <MenuItem onClick={handleHomeClick}>Home</MenuItem>

              <MenuItem onClick={() => {
                setUploadShow(true);
                handleDrawerClose();
              }}>
                Work Sets
              </MenuItem>
              <MenuItem onClick={() => {
                setProfileShow(true);
                handleDrawerClose();
              }}>
                Profile</MenuItem>
              <MenuItem onClick={handleDocView}>
                Document View
              </MenuItem>
              <MenuItem onClick={handleDocView2}>
                Document View2
              </MenuItem>
              <MenuItem onClick={handleBridgingDocView}>
                Bridge View
              </MenuItem>
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
            >
              DuoCAMRA
            </Typography>
            <form>
              <FormControlLabel control={<Switch checked={useAIInterpreter} onChange={handleInterpreterSwitchChange} />} label="Interpreter" />
            </form>
          </Toolbar>
        </AppBar>
      </Box>
      <ApiQueryForm
        show={showInterpreter}
        onHide={() => setShowInterpreter(false)}
        endpoint={endpoint}
        apiKey={apiKey}
        queryText={queryText}
        onConfirm={(newEndpoint, newApiKey, newQueryText) => {
          setEndpoint(newEndpoint);
          setApiKey(newApiKey);
          setQueryText(newQueryText);
        }}
        onCancel={() => setUseAIInterpreter(false)}
      />
      <WorksetManager
        show={uploadShow}
        onHide={() => setUploadShow(false)}
        changeCurrentWorkset={handleChangeCurrentWorkSet}
      />
      <ProfileManager
        show={profileShow}
        onHide={() => setProfileShow(false)}
      />
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
        // onClick={handleLoadingClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <SplitPane
        style={{ marginTop: "60px" }}
        split="horizontal"
        minSize={"100%"}
        onDragFinished={(height) => {
          setHeightValue(`${height - 40}px`);
        }}
      >
        <SplitPane split="vertical" minSize={"33%"}>
          <div style={{ height: '100%', overflow: 'auto' }}>
            <DocViewTextPanel text={docText} tokens={styledTokens} setTokens={setStyledTokens} selectedCluster={currentCluster} allClusters={allClusters} setAllClusters={setAllClusters} uploadAMRCorefs={handleUploadAMRCorefs} scrollToAMR={scrollToAMRBox}/>
          </div>
          <SplitPane split="vertical" minSize={"50%"}>
            <div style={{ height: '100%', overflow: 'auto' }}>
              <DocAMRPanel
               sentAMRs={amrData}
               setSentAMRs={setAMRData}
               codeOnly={false}
               allClusters={allClusters}
               setAllClusters={setAllClusters}
               uploadAMRCorefs={handleUploadAMRCorefs}
               selectedCluster={currentCluster}
               alignedTokens={styledTokens}
               setAlignedTokens={setStyledTokens}
               amrBoxRefs={boxRefs}
              />
            </div>
            <div style={{ height: '100%', overflow: 'auto' }}>
              <BridgingMapper 
                onCreateNewCluster={handleCreateNewCluster}
                onDeleteCluter={handleRemoveCluster}
                allClusters={allClusters}
                setAllClusters={setAllClusters}
                uploadAMRCorefs={handleUploadAMRCorefs}
                currentCluster={currentCluster}
                setCurrentCluster={setCurrentCluster}
                useAIInterpreter={useAIInterpreter}
              />
            </div>
          </SplitPane>
        </SplitPane>      
      </SplitPane>
    </>
  )
}

export default BridgingDocView;
