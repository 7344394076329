

export const textPanelSelectionUpdater = (newNodes, allClusters, setAllClusters, selectedCluster, tokenVar, tokenSurface, uploadAMRCorefs) => {
      // Filter out any items that match tokenVar & tokenSurface
      const filteredNodes = newNodes.filter(
        (nodeDict) => nodeDict.var !== tokenVar
      );

      if (filteredNodes.length < newNodes.length) {
        // Means at least one match existed and was removed
        // So we do NOT add anything new
        const newClusters = {
          ...allClusters,
          [selectedCluster]: {
            ...allClusters[selectedCluster],
            nodes: filteredNodes,
            name:
              allClusters[selectedCluster].name.length === 0
                ? tokenSurface
                : allClusters[selectedCluster].name
          }
        };

        setAllClusters(newClusters);
        uploadAMRCorefs(newClusters);
      } else {
        // No matches found, so add one
        const newClusters = {
          ...allClusters,
          [selectedCluster]: {
            ...allClusters[selectedCluster],
            nodes: [
              ...allClusters[selectedCluster].nodes,
              { var: tokenVar, surface: tokenSurface }
            ],
            name:
              allClusters[selectedCluster].name.length === 0
                ? tokenSurface
                : allClusters[selectedCluster].name
          }
        };

        setAllClusters(newClusters);
        uploadAMRCorefs(newClusters);
      }
    };
