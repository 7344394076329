import React, { useState, useEffect } from 'react';
import { 
  Card, 
  CardContent, 
  CardActions, 
  Container, 
  Chip, 
  Typography, 
  IconButton, 
  Fab, 
  Stack, 
  Button,
  Popover,
  InputAdornment,
  FormControl,
  OutlinedInput,
  InputLabel,
  Box
} from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import { styled } from '@mui/material/styles';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

import InterpreterRender from './InterpreterRender';
import "./AMRMapper.css";

/* 
 * A small vertical bar for display between var and surface 
 */
const DividerIcon = () => (
  <span style={{ height: '24px', borderLeft: '3px solid grey', marginLeft: '8px', marginRight: '8px' }}></span>
);

/*
 * Customizable StyledCard to vary border color based on cluster type
 */
const StyledCard = styled(Card, { name: 'StyledCard' })(
  ({ theme, hoverShadow = 1, selected, clusterType }) => {
    // Feel free to choose more "candy" colors for the border

    return {
      ':hover': {
        boxShadow: theme.shadows[hoverShadow],
      },
      backgroundColor: selected ? '#539eeb' : theme.palette.background.paper,
      color: selected ? theme.palette.primary.contrastText : theme.palette.text.primary,
      border: "2px solid black",
    };
  }
);

const BridgingMapper = ({
  onCreateNewCluster,         // updated to receive type, e.g. onCreateNewCluster("SetMember")
  onDeleteCluter,
  allClusters,
  setAllClusters,
  uploadAMRCorefs,
  currentCluster,
  setCurrentCluster,
  useAIInterpreter
}) => {
  const [selectedChips, setSelectedChips] = useState([]);

  useEffect(() => {
    console.log('Selected chips updated:', selectedChips);
  }, [selectedChips]);
  console.log('allClusters in BridgingMapper: ', allClusters);
  /*
   * When a Chip is clicked, mark that node as the "coreNode" in the
   * corresponding cluster. Only one coreNode per cluster.
   */
  const handleChipClick = (clusterID, nodeDict) => {
    console.log(`Chip ${nodeDict.var} clicked in cluster ${clusterID}`);
    
    // Update the chosen cluster in a copy of allClusters
    const updatedCluster = {
      ...allClusters[clusterID],
      coreNode: nodeDict  // store the selected node as "coreNode"
    };

    const updatedClusters = {
      ...allClusters,
      [clusterID]: updatedCluster
    };

    // Update local and parent state
    setAllClusters(updatedClusters);
    uploadAMRCorefs(updatedClusters);
  };

  const handleClusterClick = (clusterID) => {
    console.log(clusterID, 'has been clicked');
    setCurrentCluster(prevIndex => (prevIndex === clusterID ? null : clusterID));
  };

  const handleButtonClick = (event, clusterID) => {
    event.stopPropagation();
    onDeleteCluter(clusterID);
  };

  // --------------------------------------------------------------------------------
  // Popover logic for cluster name editing
  const [anchorEl, setAnchorEl] = useState(null);
  const [commentOpen, setCommentOpen] = useState(false);
  const [commentIdx, setCommentIdx] = useState(null);
  const [commentValue, setCommentValue] = useState('');

  const handleCommentClose = () => {
    setCommentOpen(false);
    setAnchorEl(null);
  };

  const handleCommentClick = (index, e) => {
    e.preventDefault();
    setCommentIdx(index);
    setAnchorEl(e.currentTarget);
    setCommentOpen(true);
  };

  const handleClickCommentSubmit = async (e) => {
    e.preventDefault();
    const newCluster = { ...allClusters[commentIdx], name: commentValue };
    const newClusters = { ...allClusters, [commentIdx]: newCluster };

    setAllClusters(newClusters);
    uploadAMRCorefs(newClusters);

    setCommentValue('');
    setCommentOpen(false);
  };

  const handleClickCommentDismiss = (e) => {
    e.preventDefault();
    setCommentIdx(null);
    setCommentValue('');
    setCommentOpen(false);
  };

  // --------------------------------------------------------------------------------
  // Interpreter tooltip logic
  const [interpreterOpen, setInterpreterOpen] = useState(false);
  const [anchorElInterp, setAnchorElInterp] = React.useState(null);

  const handleInterpreterPop = (e) => {
    e.stopPropagation();
    setAnchorElInterp(e.currentTarget);
    setInterpreterOpen(true);
  };

  // --------------------------------------------------------------------------------
  // Creating new clusters with a type
  const handleCreateNewCluster = (type) => {
    // pass the type up to parent so it can create a cluster of correct type
    onCreateNewCluster(type);
  };

  // --------------------------------------------------------------------------------
  return (
    <Stack m={3} gap={1} sx={{ margin: "5px 5px 5px 12px" }}>
      {/* Popover for cluster name editing */}
      <Popover 
        id='popover-comment'
        open={commentOpen}
        anchorEl={anchorEl}
        onClose={handleCommentClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
          <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
            <InputLabel>Bridge Name</InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              endAdornment={
                <InputAdornment position="end">
                  <Box display="flex">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickCommentSubmit}
                      edge="end"
                    >
                      <TaskAltIcon />
                    </IconButton>
                    <IconButton
                      aria-label="edit password"
                      onClick={handleClickCommentDismiss}
                      edge="end"
                    >
                      <HighlightOffIcon />
                    </IconButton>
                  </Box>
                </InputAdornment>
              }
              value={commentValue}
              onChange={(e)=> setCommentValue(e.target.value)}
              label="note"
            />
          </FormControl>
        </Box>
      </Popover>

      <InterpreterRender 
        open={interpreterOpen} 
        setOpen={setInterpreterOpen} 
        anchorEl={anchorElInterp}
      />

      {/* Render all clusters */}
      {Object.entries(allClusters).map(([clusterID, cluster]) => {
        return (
          <StyledCard
            hoverShadow={10}
            id={`cluster-${clusterID}`}
            key={clusterID}
            selected={currentCluster === clusterID}
            clusterType={cluster.type}    // Pass cluster type for border color styling
            onClick={() => handleClusterClick(clusterID)}
            sx={{ border: '1px solid darkgray' }}
          >
            <CardContent sx={{ paddingBottom: '5px' }}>
              {cluster.nodes.map((nodeDict) => {
                // Check if this chip is the "coreNode"
                const isCore = cluster.coreNode?.var === nodeDict.var;

                return (
                  <Chip
                    key={`${nodeDict.var}-${nodeDict.surface}`}
                    className="m-1 document-chip"
                    onClick={(e) => {
                      e.stopPropagation(); 
                      handleChipClick(clusterID, nodeDict);
                    }}
                    sx={{
                      border: isCore ? '3px solid #000000' : '1px solid #000000',
                      backgroundColor: isCore ? (cluster.type === 'SetMember' ? '#e000a9' : '#47b27d') : 'white',
                      color: 'black',
                      fontWeight : isCore ? '700' : '400',
                      marginRight: '5px',
                      marginBottom: '5px'
                    }}
                    label={(
                      <>
                        {nodeDict.var}
                        <DividerIcon />
                        {nodeDict.surface}
                      </>
                    )}
                  />
                );
              })}
            </CardContent>
            <CardActions sx={{ paddingTop: "0px" }}>
              <Stack 
                direction="row" 
                justifyContent="space-between" 
                sx={{ width: '100%' }}
              >
                <Box>
                  <Button
                    size="small"
                    id={cluster.id}
                    onClick={(e) => handleButtonClick(e, clusterID)}
                    color="error"
                    variant="contained"
                    sx={{
                      borderRadius: '5px',
                      padding: '4px 4px',
                      textTransform: 'none',
                      marginRight: '2px'
                    }}
                  >
                    remove
                  </Button>
                  {useAIInterpreter && (
                    <Button
                      size="small"
                      id={cluster.id}
                      onClick={handleInterpreterPop}
                      color="warning"
                      variant="contained"
                      sx={{
                        borderRadius: '5px',
                        padding: '4px 4px',
                        textTransform: 'none'
                      }}
                    >
                      Interpret
                    </Button>
                  )}
                </Box>
                <Typography 
                  variant="h6" 
                  color="text.secondary" 
                  onContextMenu={(e) => handleCommentClick(clusterID, e)}
                >
                  {cluster.name}
                </Typography>
              </Stack>
            </CardActions>
          </StyledCard>
        );
      })}

      {/* Two fabs, each creating a cluster of a different type */}
      <Box
        sx={{
    position: 'fixed', 
    bottom: 16,
    right: 16,
    display: 'flex',
    flexDirection: 'row',
    gap: 2,         // space between FABs
        }}
      >
        <Fab
          variant="extended"
          sx={{
            // Replace these with your desired hex values
            backgroundColor: '#b9008b',
            color: '#ffffff',
            '&:hover': {
              backgroundColor: '#e000a9'
            }
          }}
          // sx={fabStyleSetMember}
          aria-label="Add SetMember"
          onClick={() => handleCreateNewCluster("SetMember")}
        >
          <AddIcon sx={{ mr: 1 }} />
          SetMember
        </Fab>

        <Fab
          variant="extended"
          sx={{
            // Replace these with your desired hex values
            backgroundColor: '#40a070',
            color: '#ffffff',
            '&:hover': {
              backgroundColor: '#47b27d'
            }
          }}
          // sx={fabStylePartWhole}
          aria-label="Add PartWhole"
          onClick={() => handleCreateNewCluster("PartWhole")}
        >
          <AddIcon sx={{ mr: 1 }} />
          PartWhole
        </Fab>
      </Box>
    </Stack>
  );
};

export default BridgingMapper;