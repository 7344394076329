import React from "react";
import { Routes, Route } from "react-router-dom";
import AuthGuard from "../guards/AuthGuard";
import App from "../components/App";
import DocView from "../components/DocView";
import DocView2 from "../components/DocView2";
import BridgingDocView from "../components/BridgingDocView";

console.log("in AuthRoutes!!!!");
const AuthRoutes = [
        <Route key="Editor" path="/" element={<AuthGuard children={<App />} />} />,
        <Route key="DocView" path="/docview" element={<AuthGuard children={<DocView />} />} />,
        <Route key="DocView2" path="/docview2" element={<AuthGuard children={<DocView2 />} />} />,
        <Route key="BrDocView" path="/bridgingdocview" element={<AuthGuard children={<BridgingDocView />} />} />
    ];

export default AuthRoutes;